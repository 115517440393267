const marpatimesobj = [{north:{
        name: 'מרפ"א צפון',
        fullName: '8281 (בית חולים 10)',
        lab: {
            time: "א' - ה' 08:00 - 11:45",      
            phoneNumber: '04-830-0221',
            more: ''
        },
        pharmacy:{
            time: "א' - ה' 08:30 - 16:30",      
            phoneNumber: '04-830-0316',
            more: ''
        },
        xrey:{
            time: " א' - ה' 12:30 - 15:30 08:30 - 12:00" ,      
            phoneNumber: '04-830-0229',
            more: ''
        }
           
         
    }},
    {center:{
        name: 'מרפ"א מרכז',
        fullName: '8282 (צריפין)',
        lab: {
            time: "א' - ה' 08:00 - 13:00",
            phoneNumber: '03-957-1838',
            more: 'מענה טלפוני ינתן בין השעות 13:00 - 16:00'
        },
        pharmacy:{
            time: "א' - ה' 08:00 -17:00 ו' - 08:00 - 13:00" ,      
            phoneNumber: '03-957-3123',
            more: 'ניתן לפנות במייל הצבאי "בית מרקחת מרפ"א צריפין" CTL + K'
        },
        xrey:{
            time: " א' - ה'  13:00 - 16:00  08:00 - 12:00",      
            phoneNumber: '03-957-9306',
            more: ''
        }
    }},
    {south:{
        name: 'מרפ"א דרום',
        fullName: '8283 (עיר הבה"דים)',
        lab: {
            time: "א' - ה' 08:00 - 12:00",
            phoneNumber: '073-377-3757/8',
            more: 'מענה טלפוני ינתתן בין השעות 12:00 - 16:00'
        },
        pharmacy:{
            time: "א' - ה' 08:00 - 16:30",      
            phoneNumber: '073-377-3780/1',
            more: ''
        },
        xrey:{
            time: " א' - ה' 08:00 - 16:00:",      
            phoneNumber: '073-377-3803/4',
            more: ''
        }
    }},
    {jerusalem:{
        name: 'מרפ"א ירושלים',
        fullName: '(מחנה עופרית)',
        lab: {
        time: "א' - ה' 08:00 - 10:00",
        phoneNumber: '--',
        more: ''
        },
        pharmacy:{
            time: " א' - ה'  13:00 - 16:30 08:30 - 12:00",      
            phoneNumber: '02-542-5215',
            more: 'יש להתעדכן טרם ההגעה בשעות הפעילות'
        },
        xrey:{
            time: "א' - ה' 08:30 -  13:00 - 15:00 12:00",      
            phoneNumber: '02-542-5232',
            more: ''
        }
    }},
    {golan:{
        name: 'מרכז רפואי גליל',
        fullName: '(מחנה פילון)',
        lab: {
        time: "א' - ה' 08:00 - 10:30",
        phoneNumber: '04-691-727-481',
        more: ''
        },
        pharmacy:{
            time: "א' - ה' 08:30 - 16:30",      
            phoneNumber: '073-340-9330',
            more: ''
        },
        xrey:{
            time: "א' - ה' - 08:30 - 14:30 ",      
            phoneNumber: '--',
            more: ''
        }
    }},
    {arava:{
        name: 'מרפ"א ערבה',
        fullName: 'ניתן לפנות בכל שאלה למרפאה בהודעת וואטסאפ או בשיחה טלפונית למספר 052-381-7083',
        lab: {
            time: "ב - ד 08:00 - 10:00",
        phoneNumber: '',
        more: ''
        },
        pharmacy:{
            time: "  א' - 17:00 - 21:00 / ב' - ד': 8:00 - 20:00 / ה' 08:00 - 20:00   ",      
            phoneNumber: '--',
            more: ''
        },
        xrey:{
            time: "יש להתעדכן בוואטסאפ המרפאתי",      
            phoneNumber: '052-381-7083',
            more: 'השירות יפתח בהמשך'
        }
    }},
    {telHashomer:{
        name: 'מרפאת תל השומר',
        fullName: 'ניתן לפנות בכל שאלה למרפאה בהודעת וואטסאפ או בשיחה טלפונית למספר 052-952-1455',
        lab: {
            time: "א' - ה' 08:30 - 11:30",
        phoneNumber: '--',
        more: ''
        },
        pharmacy:{
            time: "א' - ה' 08:30 - 12:00",      
            phoneNumber: '03-737-7889',
            more: ''
        },
        xrey:{
            time: "--",    
            phoneNumber: '--',
            more: ''
        }
    }},
    {matcal:{
        name: 'מרכז רפואי מטכ"ל',
        fullName: ' ניתן לפנות הכל שאלה למרפאה בהודעת וואטסאפ למספר 052-924-7542 (קריה)',
        lab: {
            time: "א' - ה' 08:00 - 11:00",
        phoneNumber: '052-924-7542',
        more: 'בתיאום מראש בוואטסאפ'
        },
        pharmacy:{
            time: " א' - ה'  13:00 - 17:00  08:00 - 12:00",      
            phoneNumber: '04-830-0221',
            more: ''
        },
        xrey:{
            time: "--",      
            phoneNumber: '--',
            more: ''
        }
    }},
  
]

    export default marpatimesobj;