import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
const pages = ['מחשבונים', 'דגלים אדומים', 'שעות פעילות מרפאות', 'פיזותרפיה'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

 

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
<Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" >
        <Toolbar sx={{display: 'flex', justifyContent: 'center'}}>
        <img style={{height:'30px'}}  src='https://i.imgur.com/W8BEd8w.png'/>
    
         
          <Typography variant="h6" component="div" onClick={() => props.setPage('main')} sx={{ flexGrow: 1, textAlign: 'center' }}>
            IDFMedic.com
          </Typography>
          <Button onClick={()=>props.setPage('login') } color="inherit">לבדיקת ווסט</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default ResponsiveAppBar;