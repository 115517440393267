import React,{useState, useEffect} from 'react';
import{Card, Typography,InputLabel,DialogTitle, Dialog,Stack,Button, FormControl, TextField,MenuItem,Select, FormControlLabel, Checkbox, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import allLIsts from '../utils/lists.js'


const Cold = () =>{
   
    const [Ctype, setCtype] = useState('');
    const [txt, setText] = React.useState('');
    const [infoTxt, setinfoTxt] = React.useState('');
    const [Cvalue, setCvalue] = React.useState('');
    const [rain, setRain] = React.useState(false);
    const [snow, setsnow] = React.useState(false);
    const [hail, setHail] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [list, setList] = React.useState([])
    useEffect(() => {
        const badWether = snow || hail;
        if(Cvalue !== '' && Cvalue !== ''){

            if(Ctype === 'sleep' ){
                if((Cvalue > 8 && Cvalue <= 26 ) && !badWether){
                    setText('תתבצע בתוך שק"ש כאשר החייל לבוש עם ביגוד תחתון ארוך ומדי ב')
                }else if((Cvalue >= 1 && Cvalue <= 8  ) && !badWether){
                    setText('כל חייל יצוייד בלבוש ע"פ רשימה א ויישן בתוך אוהל או מבנה')
                } else if(Cvalue < 1 || badWether ){
                    setText('כל חייל יצוייד בלבוש עפ"י רשימה א ויישן בתוך אוהל / מבנה שיש בו אמצעי חימום פעיל ותקין')
                }
            }else if(Ctype === 'sty'){
                if((Cvalue > 8 && Cvalue <= 26  ) && !badWether){
                    setText('כל חייל יצוייד בלבוש המפורט ברשימה ב')
                }else if(Cvalue < 1 || badWether){
                    setText('כל חייל יצוייד בלבוש המפורט ברשימה ג. אין לבצע שהייה בביגוד רטוב יותר מ - 15 דקות. לאחר כל שעתיים שהייה בתנאי קור (או לאחר 3 שעות במידה ויש נעליים קנדיות) תבוצע הפסקה בת 30 דקות לפחות במקום מחומם ומוגן מרוח וגשםותינתן שתייה חמה')
                }
            }else if(Cvalue === 'easy'){
                if((Cvalue > 8 && Cvalue <= 26  ) && !badWether){
                    setText('כל חייל יצוייד בלבוש המפורט ברשימה ד. אין הגבלות פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים')
                }else if((Cvalue < 8 && Cvalue >= -3  ) && !badWether){
                    setText('כל חייל יצוייד בלבוש המפורט ברשימה ג. אין לבצע שהייה בבידגוד רטוב של יותר מ - 15 דקות')
                }else if( Cvalue > -3 || badWether){
                    setText('אין לבצע אימונים אלא באישור מאו"ג')
                }
            }else if(Cvalue === 'medium' && Cvalue === 'hard' ){
                if((Cvalue > 8 && Cvalue <= 26  ) && !badWether){
                    setText('כל חייל יצוייד בלבוש המפורט ברשימה ד. אין הגבלות פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים')
                }else if((Cvalue < 8 && Cvalue >= -3  ) && !badWether){
                    setText('כל חייל יצוייד בלבוש המפורט ברשימה ד. לאחר כל 60 דקות פעילות תינתן לכל חייל מנוחה של 10 דקות בה תינתן שתייה חמה. מיד בסיון האימון יוחלף הביגוד לביגוד יבש. אין לבצע שהייה בביגוד רטוב של יותר מ 15 דקות')
                }else if( Cvalue > -3 || badWether){
                    setText('אין לבצע אימונים אלא באישור מאו"ג')
                }
            }
        }else(setText('נא להזין ערכים תקינים'))
      },[Cvalue, Ctype, rain, hail, snow ] );

    const numChange = (e) => {
        if(parseFloat( e.target.value) > 40  ){
            setText("נא להכניס מספר תקין")
        }
        setCvalue(e.target.value);
    }

    const handleChange = (event) => {
        setCtype(event.target.value);
     
      };
      const rainChange = (e) =>{
        setRain(e.target.checked)
        if(e.target.checked){
            let temp = parseInt(Cvalue) 
            temp +=1;
           setCvalue(temp.toString()) ;
        }else{
            let temp = parseInt(Cvalue) 
            temp -=1;
           setCvalue(temp.toString()) ;
        }
      }
      const hailChange = (e) =>{
        setHail(e.target.checked)
      }
      const snowChange = (e) =>{
        setsnow(e.target.checked)
      }

      const handleClickOpen = (e) => {
        setOpen(true);
        if(e.target.value === 'a'){
            setList(allLIsts[0])
        }else if(e.target.value === 'b'){
            setList(allLIsts[1])
        }else if(e.target.value === 'c'){
            setList(allLIsts[2])
        }else 
            setList(allLIsts[3])
      };
      const handleClose = () => {
        setOpen(false);
       
      };
    return (
        <Card
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '1em',
            height: '50vh',
            margin: '1em 0.5em'
        }}  id="cold">
            <Typography variant="h5">מחשבון עומס קור</Typography>
            <Box>
            <FormControlLabel control={<Checkbox />}  checked={rain} onChange={rainChange} label="גשם" />
            <FormControlLabel control={<Checkbox />}  checked={snow} onChange={snowChange} label="ברד" />
            <FormControlLabel control={<Checkbox />}   checked={hail} onChange={hailChange} label="שלג" />
           
            </Box>
            <TextField 
            label="ערך קסטרל"
            onChange={numChange}
            value={Cvalue}
            type="number"
            />
             <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">סוג פעילות</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={Ctype}
                label="סוג מאמץ"
                onChange={handleChange}
                >
                
                <MenuItem value={"sleep"}> שינה  </MenuItem>
                <MenuItem value={"sty"}> שהייה</MenuItem>
                <MenuItem value={"easy"}>מאמץ קל </MenuItem>
                <MenuItem value={"medium"}>מאמץ בינוני</MenuItem>
                <MenuItem value={"hard"}>מאמץ קשה</MenuItem>
            </Select>
            </FormControl>

            <Typography variant='variant="caption"'> {infoTxt} </Typography>
            <Typography>{txt} </Typography>


                <Typography variant="body2">רשימות ביגוד לקור</Typography>
            <Stack spacing={0} direction="row" justifyContent="space-between">
            <Button size="small" onClick={handleClickOpen} value="a" variant="outlined"> א'</Button>
            <Button size="small" onClick={handleClickOpen} value="b" variant="outlined"> ב'</Button>
            <Button size="small" onClick={handleClickOpen} value="c"variant="outlined"> ג'</Button>
            <Button size="small" onClick={handleClickOpen} value="d"variant="outlined"> ד'</Button>
            <Dialog open={open} onClose={handleClose} >
           <Stack
           sx={{
            padding: '1em',
           }} >
            <DialogTitle>רשימת ציוד</DialogTitle>
            {list.map((i, e) => {
                         return( <Typography> - {i}</Typography>)
                })}
                </Stack>
            </Dialog>
    </Stack>
        </Card>
    );
}

export default Cold;