import React, { useState, useEffect } from 'react';
import { Grid, Card, Typography,TextField, Box} from '@mui/material';
import dayjs from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ColdHeat from './coldHeat.js';
import Cold from './cold.js';


const now = dayjs();
const Calcs = () => {
    
    const [date, setDate] = useState(now);
    const [time, setTime] = useState(now);
    const [num, setnum] = useState(1);
    const [txt, settxt] = useState('xxx');

  useEffect(() =>  {
calcGimelim();
  })

    const dateChange = (e) =>{
        setDate(e);
       
    }

    const timeChange = (e) =>{
        setTime(e)
      
    }
   
    const numChange = (e) =>{
        setnum(e.target.value);
        calcGimelim();
    }
    const calcGimelim = () =>{
        let gimelSum = num;
        let comeBack = '';
        
         const month = date.month() + 1;
        gimelSum = parseFloat(gimelSum) ;
        if(time.hour() < 8 ){
            const day =  now.add(gimelSum, 'day');
             comeBack = day.$D + '/' + month + " 08:00 " 
           
            settxt(comeBack)
        }else{
            gimelSum += 1;
            const day =  date.add((gimelSum), 'day');
             comeBack = day.$D + '/' + month + " 08:00 " 
             settxt(comeBack)
        }
        gimelSum = num;
       }
    return (
        <Box>
        <Grid container spacing={4}>
            <Grid item lg={4} xs={12} sm={4}  >
                <Card 
                spacing={2}
                justifyContent='center'
                sx={{ padding: '1em',display:'flex', flexDirection: 'column', height: '19em', justifyContent: 'space-between'}}
                >
                    <Typography variant="h5">מחשבון גימלים</Typography>
                 
                    <DatePicker
                    label="תאריך קבלת הגימלים"
                    defaultValue={now}
                    onChange={dateChange}
                     
                      format={'DD/MM'}
                      views={[ 'day', 'month']}
          />
         
            

                    <TimePicker
                    
                     label="שעת קבלת הגימלים"
                     defaultValue={now}
                    
                     onChange={timeChange}
                    
                     ampm={false}
                     viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                     />
                    <TextField
                    label="מספר הגימלים"
                    onChange={numChange}
                    defaultValue="1"
                    
                    />
                    <Typography>תוקף הגימלים עד: {txt}</Typography>
     

                    

                </Card>
            </Grid>

            <Grid item lg={4} xs={12} sm={4}>
                      <ColdHeat />
            </Grid>

            <Grid item lg={4} xs={12} sm={4}>
                    <Cold />
            </Grid>

        </Grid>
        </Box>
    );
}

export default Calcs;