import React, { useEffect } from 'react';
import{Card, Typography,InputLabel, FormControl, TextField,MenuItem,Select} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
const  ColdHeat = ( )=> {

    
    const [txt, setText] = React.useState('');
    const [infoTxt, setinfoTxt] = React.useState('');
    const [water, setTwater] = React.useState('');
    const [HCvalue, setHCvalue] = React.useState('');
    const [HCtype, setHCtype] = React.useState('');


    useEffect(() => {
        if(HCvalue !== '' && HCvalue !== ''){

            if(HCtype === 'none'){
                setinfoTxt("פעילוות תיאורטית במקום מוצל / הליכה עד למרחק של 500 מ' ליום")
                setText('ללא הגבלה')
                setTwater('250 מ"ל לשעה')
            }else if(HCtype === 'easy'){
                    setinfoTxt(" אימונים ע''ג רגב ורק''מ /  מטווחים נייחים / הליכה מעל 500 מ' ולא יותר מ2000 מטר' ליום (לרבות אפוד ונשק) /תרגול טכני")
                if( HCvalue <= 22){
                    setText('ללא הגבלה')
                    setTwater('250 מ"ל לשעה')
                } else if( HCvalue >= 22 && HCvalue <= 24){
                    setText('ללא הגבלה')
                    setTwater('250 מ"ל לשעה')
                } else if( HCvalue > 24 && HCvalue <= 26){
                    setText('ללא הגבלה')
                    setTwater('750 מ"ל לשעה')
                }else if( HCvalue > 26 && HCvalue <= 28){
                    setText('  (הכשרות יסוד, מילואים ואוכלוסיות מיוחדות - יחשב כעומס חום כבד) לאחר 45 דקות עבודה יש לבצע 15 דקות מנוחה בצל')
                    setTwater('750 מ"ל לשעה')
                }else if( HCvalue > 28 && HCvalue <= 30){
                    setText('לאחר 45 דקות עבודה יש לבצע 15 דקות מנוחה בצל')
                    setTwater('750 מ"ל לשעה')
                }else if( HCvalue > 30){
                    setText(' (באישור אל"מ המהווה סמכות פיקודית על היחידה המתאמנת ובהתייעצות עם רופא) לאחר 45 דקות עבודה יש לבצע 15 דקות מנוחה בצל')
                    setTwater('1000 מ"ל לשעה')
                }
            }else if(HCtype === 'medium'){
                setinfoTxt('תרגילים או אימונים רגליים / מסע רגיל או תנועה רגלית (עד 6 קמ"ש במישור עם אפוד, נשק ואגמ""ק) / מסע עד 30 ק"מ משוקלל / ניווטים / מטווחים בתנועה או לאחר מאמץ / אימוני כח וקרב מגע בסיסי / ריצה קצב קל')
                if( HCvalue <= 22){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל, אחת ליום ניתן לבצע פעילות רצופה של 120 דקות ולאחריה 30 דקות מנוחה')
                    setTwater('500 מ"ל לשעה')
                } else if( HCvalue >= 22 && HCvalue <= 24){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל, אחת ליום ניתן לבצע פעילות רצופה של  120  דקות ולאחריה 30 דקות מנוחה')
                    setTwater('750 מ"ל לשעה')
                } else if( HCvalue > 24 && HCvalue <= 26){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל, אחת ליום ניתן לבצע פעילות רצופה של 120 דקות ולאחריה 30 דקות מנוחה')
                    setTwater('1000 מ"ל לשעה')
                }else if( HCvalue > 26 && HCvalue <= 28){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל, אחת ליום ניתן לבצע פעילות רצופה של 120 דקותולאחריה 30 דקות מנוחה  (הכשרות יסוד, מילואים ואוכלוסיות מיוחדות - יחשב כעומס חום כבד)  ')
                    setTwater('1000 מ"ל לשעה')
                }else if( HCvalue > 28 ){
                    setText('אין לבצע פעילות במאמץ בינוני ומעלה')
                    setTwater('250 מ"ל לשעה')
                }
            }else if(HCtype === 'hard'){
                setinfoTxt('תרגילים או אימונים רגליים / מסע רגיל או תנועה רגלית (עד ק"מ / תנועה רגלית בתנאי שטח קשחם / מסע רגיל או פקל"ים ותנועה רגלית מעל 30 ק"מ משוקלל / מסע מזורז 6 קמ"ש / מסע אלונקות / קרב מגע מתקדם / ריצות')
                if( HCvalue <= 22){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל, אחת ליום ניתן לבצע פעילות רצופה של 120 דקות ולאחריה 30 דקות מנוחה')
                    setTwater('750 מ"ל לשעה')
                } else if( HCvalue >= 22 && HCvalue <= 24){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל, אחת ליום ניתן לבצע פעילות רצופה של 120 דקות ולאחריה 30 דקות מנוחה')
                    setTwater('1000 מ"ל לשעה')
                } else if( HCvalue > 24 && HCvalue <= 26){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל, אחת ליום ניתן לבצע פעילות רצופה של 120 דקות ולאחריה 30 דקות מנוחה')
                    setTwater('1250 מ"ל לשעה')
                }else if( HCvalue > 26 && HCvalue <= 28){
                    setText('  (הכשרות יסוד, מילואים ואוכלוסיות מיוחדות - יחשב כעומס חום כבד) כל 60 דקות יש לבצע 15 דקות מנוחה בצל, אחת ליום ניתן לבצע פעילות רצופה של 120 דקות ולאחריה 30 דקות מנוחה')
                    setTwater('1250 מ"ל לשעה')
                }else if( HCvalue > 28 ){
                    setText('אין לבצע פעילות במאמץ בינוני ומעלה')
                    setTwater('250 מ"ל לשעה')
                }
            } else if(HCtype === 'super'){
                setinfoTxt('פעילויות במאמץ עצים ע"פ הוראת קחצ"ר בנושא כושר קרבי מס 4')
                if( HCvalue <= 22){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל')
                    setTwater('750 מ"ל לשעה')
                } else if( HCvalue >= 22 && HCvalue <= 24){
                    setText('כל 60 דקות יש לבצע 15 דקות מנוחה בצל')
                    setTwater('1000 מ"ל לשעה')
                } else if( HCvalue > 24 && HCvalue <= 26){
                    setText(', אישור סא"ל בהתיעעצות רופא וקא"ג כל 60 דקות יש לבצע 15 דקות מנוחה בצל')
                    setTwater('1250 מ"ל לשעה')
                
                }else if( HCvalue > 26 ){
                    setText('אין לבצע פעילות במאמץ בינוני ומעלה')
                    setTwater('250 מ"ל לשעה')
                }
            }
        }else(setText('נא להזין ערכים תקינים'))
      },[HCvalue, HCtype] );

    const numChange = (e) => {
        if(parseFloat( e.target.value) > 40  ){
            setText("נא להכניס מספר תקין")
        }
        setHCvalue(e.target.value);
    }

    const handleChange = (event) => {
        setHCtype(event.target.value);
        console.log(HCtype)
        console.log(HCvalue)
        
      };
      
    return (
       <Card 
       sx={{ padding: '1em',display:'flex', flexDirection: 'column', height: '19em', justifyContent: 'space-between'}}

        id="hot">

            <Typography variant="h5">מחשבון עומס חום</Typography>
            <TextField 
            label="ערך קסטרל"
            onChange={numChange}
            type="number"
            />
           
            <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">סוג פעילות</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={HCtype}
                label="סוג מאמץ"
                onChange={handleChange}
                >
                
                <MenuItem value={"none"}> ללא מאמץ </MenuItem>
                <MenuItem value={"easy"}>מאמץ קל </MenuItem>
                <MenuItem value={"medium"}>מאמץ בינוני</MenuItem>
                <MenuItem value={"hard"}>מאמץ קשה</MenuItem>
                <MenuItem value={"super"}>מאמץ עצים</MenuItem>
            </Select>
            </FormControl>

            <Typography variant='variant="caption"'>{<InfoIcon font-size="small" color="primary" />} {infoTxt} </Typography>
            <Typography>{txt} </Typography>
            <Typography> החזר נוזלים: {water}  </Typography>
       </Card>
    );
}

export default ColdHeat;