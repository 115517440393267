const redFlags = [{
    headache:{
        name:"כאבי ראש",
        qustions: [
            "האם הופיע בצורה חדה ופתאומית?",
            "האם הכאב חריג?",
            "כאב חוזר ונשנה?",
            "התגברות הכאב בהטיית הראש לפנים?",
        "מלווה בבחילות או הקאות?",
        "מלווה בהפרעות ראייה, תחושת נימול או תחושת חולשה בגפיים?",
        "כאב ראש המעיר משינה?",
        "תרופה קודמת לא עזרה?",
        "כתוצאה מחבלה?",
       "כאב ראש המלוווה בחום?"
        ],
        redFlags: [],
        treetmant:[
                    "Tab. Acamol/Dexamol (Paracetamol)" ,
                    "1 - 2 כל 4 שעות. כמות מקסימלית לניפוק: 6",
                    " התוויות נגד: מחלות כבד / אין לשלב עם אלכוהול / אלרגיה" ,
                     "Tab.Optalgin (Dipyrone)" ,
                    "1 - 2 כל 8 שעות. מקסימות לניפוק: 6 (עד 12 טבליות לחודש)",
                    "התוויות נגד:  להפריד שעה מאקמול / מחלות כבד, כליה ודם בירור דיכוי מח עצם או בעיה בייצור תאי דם לבנים / נשים מניקות או בהריון / אלרגיה" 
   ]
    },
    throutPain:{
        name:"כאב גרון",
        qustions: [
            "כתוצאה מחבלה? (אם כן - פינוי מיידי)"
        ],
        redFlags: [
            "כאב גרון בזמן בליעה",
            "כאב גרון בזמן נשימה",
            "כאב אינו חולף 24 שעות לאחר תחילת טיפול"
        ],
        treetmant:[
          
                    "Loz.Kalgaron / Rafrathricin (Tyrothricin & Benzocaine)",
                     "1 כל 4 שעות. כמות מקסימלית לניפוק: 6. במציצה - לא לאכול / לשתות שעה לאחר הנטילה",
                     "התוויות נגד: אלרגיה"
            
        ]
    },
    guck:{
        name:"נזלת",
        qustions: [
            "נזלת מוגלתית? (צבע ומרקם)",
            "נזלת ויראלית?",
            "נזלת אלרגית?(באופן קבוע? עונתי? אלרגיה ידועה?) ",
        ],
        redFlags: [
            "כאב גרון בזמן בליעה",
            "כאב גרון בזמן נשימה",
            "אם חלה החמרה או כאב שאינו חולף תוך 24 שעות ",
           
        ],
        treetmant:[
           
                "Sol.Sinulen (Oxymetazolin)",
                 "2 טיפות לכל נחיר 3 פעמים ביום, לא יותר מ 3 ימים",
                " התוויות נגד: נזלת כרונית / אלרגיה" 
            
        ]
    },
    teath:{
        name:"שיניים",
        qustions: ["כתוצאה מחבלה?"],
        redFlags: ["טראומה"],
        treetmant:["בכל טראומה בה השן יוצאת ממקומה, יש להחזירה ולפנות במיידי לרופא השיניים. במידה שלא ניתן להחזיר את השן או שהמטופל מעולפל הכרה, יש להכניס את השן לכוס חלב / רוק"]
    },
    cough:{
        name:"שיעול",
        qustions: [],
        redFlags: [
            "שיעול דמי",
           "שיעול המלווה בחום",
           "שיעול חריג",
           "שיעול המלווה בכאבים בחזה"
        ],
        treetmant:[]
    },
    stomech:{
        name:"כאבי בטן",
        qustions: [],
        redFlags: [
            "כאבים באיזור המותניים",
           "כאב בטן המלווה בחום",
           "כאב בטן המלווה בכאב אשכים",
           "כאב בבטן הימנית התחתונה המלווה בחום, חוסר תיאבון, בחילות והקאות. "
        ],
        treetmant:[]
    },
    thoughUp:{
        name:"בחילות והקאות",
        qustions: [],
        redFlags: [
           "הקאה המלווה בסימני התייבשות",
           "בחילות והקאות המלוות בכאבים בחזה",
           "מלווה בכאבי בטן וחום גבוה",
          "הקאות דמיות",
          "מלווה בכאבי ראש"
        ],
        treetmant:[]
    },
    constepation:{
        name:"עצירות",
        qustions: [
            "בעיות נוספות במערכת העיכול?",
            "למעלה משלושה ימים?",
            "נובע מטיפול תרופתי או חוזר אחרי טיפול תרופתי?"
        ],
        redFlags: [
           "דימום רקטלי",
           "עצירות ללא גזים"
        ],
        treetmant:[
          
                "Liquid Paraffin",
                "2 כפיות פעמיים ביום ליומיים",
                " התוויות נגד: אם הופיעה בחילה או הקאה יש להפסיק את נטילת התרופה / אלרגיה"
           
    ]
    },
    dyaria:{
        name:"שלשולים",
        qustions: [
           "מלווה בהקאות?",
           "שלשול דמי?",
        "סימני התייבשות?",
        "שלשול מעל 24 שעות?",
        "מדדים תקינים?"
        ],
        redFlags: [
           "מלווה בסימני התייבשות",
           "שלשול המלווה בחום"
        ],
        treetmant:["Cap.Stopit / Imodium (Loperamid)","  2 מיד ו 1 לאחר כל שלשול. מקסימום לניפוק - 4", "יש להמליץ על שתייה מרובה, לא לאכול סיבים תזונתיים ולא לעשות פעילות גופנית", "התוויות נגד: שלשול דמי / אלרגיה"]
    },
    tzarevet:{
        name:"צרבת",
        qustions: [
          "בעיות נוספות במערכת העיכול? (דגש על הקאה דמית או 'קפה טחון')",
          "מלווה בצואה שחורה?",
          "מלווה בכאב ברום הבון או החזה?"
        ],
        redFlags: [
           "תרופה לא עוזרת",
           "צרבת המלווה בכאבים בחזה"
        ],
        treetmant:[
          
              "Tab.Tzarevet X / Tums (Calcium Carbonate) או Tab. Fresco / Rennie (Calcium Carbonate / Magnesium Carbonate)",
              "1 כל 3 שעות. מקסימום לניפוק: 8",
                "להפריד שעתיים מעל תרופה אחרת / אלרגיה" 
            
        ]
    },
    ear:{
        name:"כאבי אוזניים",
        qustions: [
      
        ],
        redFlags: [
           'סימני דלקת (א"ח נכ"ה)',
           "הפרעות בשמיעה",
           "הפרשות",
           "כאב חריג"

        ],
        treetmant:[
                    "Tab. Acamol/Dexamol (Paracetamol)" ,
                    "1 - 2 כל 4 שעות. כמות מקסימלית לניפוק: 6",
                    " התוויות נגד: מחלות כבד / אין לשלב עם אלכוהול / אלרגיה" ,
                     "Tab.Optalgin (Dipyrone)" ,
                    "1 - 2 כל 8 שעות. מקסימות לניפוק: 6 (עד 12 טבליות לחודש)",
                    "התוויות נגד:  להפריד שעה מאקמול / מחלות כבד, כליה ודם בירור דיכוי מח עצם או בעיה בייצור תאי דם לבנים / נשים מניקות או בהריון / אלרגיה" 
        ]
    },
    eye:{
        name:"כאבי עיניים",
        qustions: [
      "כתוצאה מחבלה?",
      ' חדירת גו"ז ?  '
        ],
        redFlags: [
           'סימני דלקת (א"ח נכ"ה)',
           "הפרעות בראיה",
           "הפרשות",
           "כאב חריג",
           "דמעות"

        ],
        treetmant:[
                  'במידה ויש חדירה של גו"ז / חומר כימי לעין, יש לשטוף את העין ע"י מזרק עם סליין, הרטמן או מיים (ללא המחט!)"' ]
    },
    back:{
        name:"כאבי גב",
        qustions: [
            "כתןצאה מחבלה?",
        ],
        redFlags: [
          "שינוי תורה",
          "דימום פנימי",
          'חד"ל לא תקין',
          "מלווה בתחושת חולשה כללית",
          "חולשה בגפיים",
          "נימול",
          "בריחת שתן"

        ],
        treetmant:[ ]
    },
    back:{
        name:"כאבי גב",
        qustions: [
            "כתוצאה מחבלה?",
        ],
        redFlags: [
          "שינוי צורה",
          "דימום פנימי",
          'חד"ל לא תקין',
          "לא חל שיפור לאחר 3 ימי טיפול או כויות באיזור המריחה",
          "מלווה בתחושת חולשה כללית",
          "חולשה בגפיים",
          "נימול",
          "בריחת שתן"

        ],
        treetmant:[ ]
    },
    mousle:{
        name:"כאבי שרירים",
        qustions: [
            "כתוצאה מחבלה?",
        ],
        redFlags: [
          "שינוי צורה",
          "דימום פנימי",
          'חד"ל לא תקין',
          "לא חל שיפור לאחר 3 ימי טיפול או כויות באיזור המריחה",

        ],
        treetmant:[
            "Ung.Ment O cap",
            "Ung.Deep Heat",
            "Ung.Tiger Balm",
            "Ung.Radian B",
            "- שפורפרת אחת לחודש 3 -4 פעמים ביום, מריחה עדינה בשכבה דקלה לא במקומות אינטימיים",
            "התוויות נגד: פצעים פתוחים ואיזורים רגישים / נשים בהיריון / אלרגיה לאספירין ולתרופות נוגדות דלקת"    
        ]
    },
    nows:{
        name:"דימום מהאף",
        qustions: [
           
        ],
        redFlags: [
        ],
        treetmant:[ "ספיגה של הדם בעזרת פד גזה  ללחוץ על גשר האף תוך הטיית  הראש כלפי מטה "]
    },
    ancle:{
        name:"כאבים בקרסול",
        qustions: [
           
        ],
        redFlags: [
            "חוסר יכולת לדרוך",
            "שינוי צורה בגפה"
        ],
        treetmant:[ "PRICE - protection, Rest, Ice Compretions, Elevation"]
    },
    knee:{
        name:"כאבים בברכיים",
        qustions: [
           "כתוצאה מחבלה?"
        ],
        redFlags: [
            "חום / אודם מקומי"
        ],
        treetmant:[]
    },
    openWoond:{
        name:"פצע",
        qustions: [
           "האם יש חיסון לטטנוס? (אם לא פינוי תוך 24 שעות)"
        ],
        redFlags: [
            'סימני א"ח נכ"ה (אודם, חום, נפיחות, כאב, הגבלה בתפקוד)',
            "פצע מוגלתי",
            "אודם ונפיחות מופשטים על העור",
            "קו אדום לכיוון מרכז הגוף",
            "פצע באיזור הצוואר / עמוד שדרה / איבן המין",
            "מורסה (פצע סגול / שחור)",
            "כאב חריג"
        ],
        treetmant:[ "שטיפה / חיטוי / חבישה"]
    },
    blister:{
        name:"שלפוחיות",
        qustions: [
           
        ],
        redFlags: [
            "כתוצאה מכוויה",
            "שלפוחית מזוהמת (צבע ירוק - צהוב)",
            "שלפוחית המלווה באחד הדגלים האדומים המפורטים בפצע מוגלתי"
        ],
        treetmant:[ 'ניקוב ע"י מחט סטרילית']
    },
    dryLips:{
        name:"יובש בשפתיים",
        qustions: [
           
        ],
        redFlags: [
            "סדקים עמוקים",
            "שלפוחיות"
        ],
        treetmant:[ "מריחת וזלין עד 4 פעמים ביום"]
    },
    herpis:{
        name:"הרפס",
        qustions: [
           
        ],
        redFlags: [
            "מקום אחר למעט השפתיים",
            "תלונות עיניות",
            "שניים או יותר אירועים בחצי שנה האחרונה",
            "פצע חמור / מתפשט",
            "סימני זיהום",
            "טיפול שלא הביא הטבה תוך חמישה ימים"
        ],
        treetmant:[ "Ung.Zovirex / Acyclo-V - 5 פעמים ביום ל 5 ימים",
    "התויות נגד: מריחה באיזור העיניים ואיבר המין / נשים בהיריון או מניקות / אלרגיה לתרופה"]
    },
    splinter:{
        name:"קוץ תקוע",
        qustions: [
           
        ],
        redFlags: [
            "בעין",
            "באוזן"
        ],
        treetmant:['הוצעת הקוץ ע"י פינצטה בלבד']
    },
    funges:{
        name:"פטריות",
        qustions: [
           
        ],
        redFlags: [
           "חום מקומי בעור",
           "האודם בולט על פני העור אין גבולות ברורים לאודם",
           "אדמומי במרכז וחיוור בהיקיף",
           "יש פצעים / בועות / שלפוחיות",
           "אודם מפריש",
           "לא משתפר לאחר 7 ימי טיפול"
        ],
        treetmant:[
            "Pitrion / Daktarin - למרוח 3 פעמים ביום למשך 7 ימים",
            "Fungimon / Undecyl - לפזר עד 5 פעמים ביום"
        ]
    },
    ingounTn:{
        name:"ציפורן חודרנית",
        qustions: [
           
        ],
        redFlags: [
           "עקב חבלה",
           "חוזר ונשנה",
           "מופשט מעבר לציפורן",
           "לא חל שיפור תוך  3 ימים"
        ],
        treetmant:[
            "חיטוי בסביעור  1:30 למשך 20 דק' ",
            "חיטוי וחבישה"
        ]
    },
    iching:{
        name:"גרד בעור",
        qustions: [
           
        ],
        redFlags: [
        ],
        treetmant:[
            "Calamine - עד 6 מריחות ביום "
        ]
    },
    sh:{
        name:"מערכת השתן",
        qustions: [
           "צריבה?",
           "דחיפות?",
           "תכיפות?"
        ],
        redFlags: [
            "שתן דמי",
            "גברים - כל תלונה של צריבה במתן שתן",
            "נשים - סימני דלקת + צמרמורות / כאב מהמותן / בחילות / הקאות / חום"
        ],
        treetmant:[
           
        ]
    },
}];

export default redFlags;