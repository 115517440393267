import {React, useState} from 'react'
import { Link, Typography, TextField, Button, Grid,Card } from '@mui/material';
    import users from '../utils/users.js'

const Login = (props) => {
    const [name, setname] = useState('');
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [company, setCompany] = useState('');
    const [isLogin, setIsLogin] = useState(true);

  
    const handleLoginSignup = (e) => {
      e.preventDefault();
      // You can implement login/signup logic here
      
      if(users.includes(id) && password === '97'){
          props.setPage('check');
          props.setUserName(name)
          props.setCompany(company)
      }
      if (isLogin) {
        // Perform login
      } else {
        // Perform signup
      }
    };
  return (
    <Card elevation={2} 
    sx={{
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    margin: '2em',
    padding: '1em'}} >
    <Card elevation={0} >
    <Typography color="primary" component="h1" variant="h5">התחברות</Typography>
    <form 
    sx={{ width: '100%'}}
    onSubmit={handleLoginSignup}>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="name"
            label="שם מלא"
            name="Name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
         <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="id"
            label="מספר אישי"
            name="id"
            autoComplete="id"
            autoFocus
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="CO"
            label="פלוגה"
            name="company"
            autoComplete="company"
            autoFocus
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="password"
            label="סיסמא"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
      <Button
        // href="/api/auth/login"
        type="submit"
        fullWidth
        variant="outlined"
        color="primary"
        // className={classes.submit}
      >
       כניסה
       
      </Button>
     
    </form>
    </Card>
</Card>
  )
}

export default Login