const weekList = [
    {
        name: 'C.A.T',
        Ename: "CAT",
        quantity: "3",
        
    },
{
    name: 'Combat Gauze',
    Ename: "Combat Gauze",
    quantity: "1",
    
},
{
    name: 'תחבושת ישראלית First Care',
    Ename: "First Care",
    quantity: "4",
    
},
{
    name: 'מעביר אוויר',
    Ename: "Airway",
    quantity: "2",
    
},
{
    name: 'שמיכת הצלה',
    Ename: "Blanket",
    quantity: "1",
    
},
{
    name: 'כפפות חד"פ',
    Ename: "Gloves",
    quantity: "10",
    
},
{
    name: 'פאלס',
    Ename: "Pulse Oximeter",
    quantity: "1",
    
},
{
    name: 'פד גזה',
    Ename: "gauze pad",
    quantity: "10",
    
},

{
    name: 'וונפלון G14',
    Ename: "14G",
    quantity: "2",
    
},
{
    name: 'חוסם ורידים',
    Ename: "VB",
    quantity: "1",
    
},
{
    name: 'סט עירוי',
    Ename: "IV line",
    quantity: "2",
    
},
{
    name: 'הרטמן 500 מ"ל',
    Ename: "Hartman",
    quantity: "2",
    
},
{
    name: 'וונפלון G18',
    Ename: "18G",
    quantity: "4",
    
},
{
    name: 'לויקופלסט',
    Ename: "loiko",
    quantity: "2",
    
},
{
    name: 'ספונגטות',
    Ename: "sponge",
    quantity: "10",
    
},
{
    name: 'מזרק 3 סמ"ק',
    Ename: "Syringe",
    quantity: "2",
    
},
{
    name: 'מחט G21',
    Ename: "Needle",
    quantity: "2",
    
},
{
    name: 'סליין 10 מ"ל',
    Ename: "sline",
    quantity: "8",
    
},
{
    name: 'אגד 3',
    Ename: "eged",
    quantity: "6",
    
},
{
    name: 'גזה דחוסה',
    Ename: "CGauze",
    quantity: "2",
    
},
{
    name: 'תחבושת אלסטית',
    Ename: "Elastic bandage",
    quantity: "2",
    
},
{
    name: 'תחבושת מטאלין',
    Ename: "Metalin bandage",
    quantity: "1",
    
},

{
    name: 'משולשי בד',
    Ename: "tri",
    quantity: "4",
    
},
{
    name: 'פאראצטמול 500 מ"ג',
    Ename: "Paracetamole",
    quantity: "20",
    
},
{
    name: 'מלע"כ',
    Ename: "malac",
    quantity: "1",
    
},

{
    name: 'פנ"צ',
    Ename: "Flashlight",
    quantity: "1",
    
},
{
    name: 'סוללות רזרביות',
    Ename: "Batteries",
    quantity: "5",
    
},
{
    name: 'מדחום',
    Ename: "Thermometer",
    quantity: "1",
    
},


{
    name: 'תחבושת בטן ',
    Ename: "T.B",
    quantity: "1",
    
},
{
    name: 'פחמ"ח',
    Ename: "p.m",
    quantity: "1",
    
},
{
    name: 'טוש',
    Ename: "marker",
    quantity: "1",
    
},
{
    name: 'רשמ"צ',
    Ename: "list",
    quantity: "1",
    
},

]

export default weekList;