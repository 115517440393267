const redFlags = [{
    headache:{
        name:"כאבי ראש",
        qustions: [
            
            {
                q:"האם הופיע בצורה חדה ופתאומית?",
                y:"הופיע בצורה חדה ופתאומית",
                n:"לא הופיע בצורה חדה ופתאומית"
                
            },
            {
                q: "האם הכאב חריג?",
                y: "מציין כאב חריג",
                n: "ללא כאב חריג"
            },
           { 
                q:"כאב חוזר ונשנה?",
                y: "כאב חוזר ונשנה",
                n: "כאב אינו חוזר ונשנה"
            },
            {
                q: "הכאב בעיקר בפנים / מתגבר בהטיית הראש קדימה?",
                y: "כאב מתגבר בהטיית הראש לפנים",
                n: "כאב אינו מתגבר בהטיית הראש לפנים"
            },
            {
                q: "מלווה בבחילות או הקאות?",
                y: "מלווה בבחילות והקאות",
                n:"ללא בחילו / הקאות"
            },
            {
                q: "מלווה בהפרעות ראייה, תחושת נימול או תחושת חולשה בגפיים?",
                y: "עם הפרעות נוירולוגיות",
                n: "ללא הפרעות נוירולוגיות"
            },
             {
                q: "כאב ראש המעיר משינה?",
                y: "כאב מעיר משינה",
                n:"כאב אינו מעיר משינה"
            },
            {
                q: "תרופה קודמת לא עזרה?",
                y: "נטל תרופה לשיכוך כאבים - ללא שיפור",
                n: "נטל תרופה לשיכוך כאבים - מאז חל שיפור"
            },
            {
                q: "כתוצאה מחבלה?",
                y:"כתוצאה מחבלה",
                n: "לא כתוצאה מחבלה",
            },
        ],
        isPain: true,
    },
    throutPain:{
        name:"כאב גרון",
        qustions: [
            {
                q: "כתוצאה מחבלה? (אם כן - פינוי מיידי)",
                y:"כתוצאה מחבלה",
                n:"לא כתוצאה מחבלה"
            },
            {
                q: "האם כואב בבליעה?",
                y:"עם כאב בבליעה",
                n: "ללא כאב בבליעה"
            },
            {
                q: "האם כואב בנשימה?",
                y: "עם כאב בזמן נשימה",
                n:"ללא כאב בזמן נשימה"
                },
            {
                q: "האם כאב לא חלף לאחר 24 שעות של טיפול?",
                y: "כאב לא חלף לאחר 24 שעות טיפול",
                n: "כאב משתפר לאחר טיפול במשככי כאבים"
            },
            { 
                q: "האם יש שיעול?",
                y: "מלווה בשיעול",
                n: "ללא שיעול"

          },
            { 
                q: "יש כאבים בחזה?",
                y:"עם כאבים בחזה",
                n: "ללא כאבים בחזה"
            },
            {
                 q: "האם יש צרידות?",
                 y: "עם צרידות בקול",
                 n:"ללא צרידות בקול"
              
        },

        ],
       isPain: true,
    },
    guck:{
        name:"נזלת",
        qustions: [
            { 
                q:"נזלת מוגלתית? (צבע ומרקם)",
                y: "עם נזלת מוגלתית",
                n:"ללא נזלת מוגלתית"

        },
           { 
            q:"נזלת ויראלית?",
            y: "עם נזלת ויראלית",
            n: "ללא נזלת ויראלית"
        },
            {
                q: "נזלת אלרגית?(באופן קבוע? עונתי? אלרגיה ידועה?) ",
                y: "עם נזלת אלרגית",
                n: "ללא נזלת אלרגית"
            },
        ],
       isPain: true
    },
    teath:{
        name:"כאבי שיניים",
        qustions: [
            {
                q: "כתוצאה מחבלה?",
                y:"כתוצאה מחבלה",
                n:"לא כתוצאה מחבלה"
            },
            {
                q: "האם הכאב מעיר משינה?",
                y:"כאב מעיר משינה",
                n:"כאב אינו מעיר משינה"
            },
            {
                q: "האם הכאב נסבל",
                y:"הכאב נסבל",
                n:"חש כאב בלתי נסבל"
            },
            {
                q: "כתוצאה מחבלה?",
                y:"כתוצאה מחבלה",
                n:"לא כתוצאה מחבלה"
            },
            {
                q: "כתוצאה מחבלה?",
                y:"כתוצאה מחבלה",
                n:"לא כתוצאה מחבלה"
            },
            {
                q: "האם הכאב משתנה באכליה / שתיה של אוכל חם / קר?",
                y:"כאב משתנה בחשיפה לחום / קור",
                n:"הכאב אינו משתנה בחשיפה לחום / קור"
            },
            {
                q: "האם יש נפיחות בפנים?",
                y:"עם נפיחות בפנים",
                n:"ללא נפיחות בפנים"
            },
            {
                q: "האם יש אודם בפנים?",
                y:"עם אודם בפנים",
                n:"ללא אודם בפנים "
            },
        ],
        isPain: true,
    },
    cough:{
        name:"שיעול",
        qustions: [
            {
                q:"האם יוצא דם בשיעול?",
                y:"עם שיעול דמי",
                n:"ללא שיעול דמי"
            },
            {
                q:"האם השיעול מעיר משינה?",
                y:"שיעול חריג - מעיר משינה",
                n:"ללא שיעול חריג"
            },
            {
                q:"האם השיעול מוולה בכאבים בחזה?",
                y:"מלווה בכאבים בחזה",
                n:"ללא כאבים בחזה"
            },
            {
                q:"האם מלווה בכאב גרון?",
                y:"מלווה בכאבי גרון",
                n:"ללא כאבי גרון"
            },
           
        ],
       isPain: true
    },
    stomech:{
        name:"כאבי בטן",
        qustions: [
            {
                q:"האם הכאב באיזור המותניים?",
                y:"כאב באיזור המותניים",
                n:"ללא כאב באיזור המותניים"
            },
            {
                q:"האם כואב בבטן ימנית תחתונה?",
                y:"עם כאב בבטן ימנית תחתונה",
                n:"ללא כאב בבטן ימנית תחתונה"
            },
            {
                q:"האם הכאב הולך ומתגבר?",
                y:"כאב הולך ומתגבר",
                n:"כאב אינו הולך ומתגבר"
            },
            {
                q:"האם יש שילשולים?",
                y:"עם שילשולים",
                n:"ללא שילשולים"
            },
            {
                q:"האם יש עצירות?",
                y:"עם עצירות",
                n:"ללא עצירות"
            },
            {
                q:"האם יש בחילות / הקאות?",
                y:"עם בחילות / הקאות",
                n:"ללא בחילות / הקאות"
            },
            {
                q:"האם יש תיאבון?",
                y:"תיאבון שמור",
                n:"ללא תיאבון"
            },
        ],
     isPain: true
    },
    thoughUp:{
        name:"בחילות והקאות",
        qustions: [
            {
                q:"האם קיימים סימני התייבשות?",
                y:"עם סימני התייבשות",
                n:"ללא סימני התייבשות"
            },
            {
                q:"האם יש כאבים בחזה?",
                y:"עם כאבים בחזה",
                n:"ללא כאבים בחזה"
            },
            {
                q:"האם יש הקאות דמיות?",
                y:"עם הקאות דמיות",
                n:"ללא הקאות דמיות"
            },
            {
                q:"האם יש כאבי בטן?",
                y:"מלווה בכאבי בטן",
                n:"ללא כאבי בטן"
            },
            {
                q:"האם יש שילשולים?",
                y:"עם שילשולים",
                n:"ללא שילשולים"
            },
            {
                q:"האם מלווה בכאבי ראש?",
                y:"מלווה בכאבי ראש",
                n:"ללא כאבי ראש"
            },
        ],
        isPain: true
    },
    constepation:{
        name:"עצירות",
        qustions: [
            {
               q: "בעיות נוספות במערכת העיכול?",
               y: "עם בעיות נוספות במערכת העיכול",
               n: "ללא בעיות נוספות במערכת העיכול"
            },
        {
            q: "למעלה משלושה ימים?",
            y: "העצירות נמשכת מעל שלושה ימים",
            n:"העצירות נמשכת פחות משלושה ימים",
        },
           { 
            q:"נובע מטיפול תרופתי או חוזר אחרי טיפול תרופתי?",
            y: "התחיל לאחר טיפול תרופתי",
            n:"לא התחיל לאחר טיפול תרופתי"
        },
        { 
            q:"האם יש דימום רקטלי?",
            y: "עם דימום רקטלי",
            n:"ללא דימום רקטלי"
        },
        { 
            q:"העם מלווה בגזים?",
            y: "עצירות עם גזים",
            n:"עצירות ללא גזים"        },
        ],
      isPain: false
    },
    dyaria:{
        name:"שלשולים",
        qustions: [
           {
           q: "מלווה בהקאות?",
           y: "מלווה בהקאות",
           n: "לא מלווה בהקאות"
        },
           {
           q: "שלשול דמי?",
           y: "ללא שלשול דמי",
           n: "עם שלשול דמי"
        },
        {
            q: "סימני התייבשות?",
            y: "עם סימני התייבשות",
            n: "ללא סימני התייבשות"
        },
        {
            q: "שלשול מעל 24 שעות?",
            y: "שלשול מעל 24 שעות",
            n: "שלשול התחיל לפני פחות מ24 שעות"
        },
        
      
        ],
      isPain: false
     },
    tzarevet:{
        name:"צרבת",
        qustions: [
            { 
                q:"האם יש הקאה דמית?",
                y: "עם הקאה דמית",
                n:"ללא הקאה דמית"
            },
            { 
                q:"האם יש הקאה דמוי קפה טחון?",
                y: "עם הקאה דמוי קפה טחון",
                n:"ללא הקאה דמוי קפה טחון"
            },
            { 
                q:"האם מלווה בצואה שחורה?",
                y: "מלווה בצואה שחורה",
                n:"לא מלווה בצואה שחורה"
            },
            { 
                q:"האם יש כאב ברום הבטן / חזה?",
                y: "עם כאב ברום הבטו / חזה",
                n:"ללא כאב ברום הבטן / חזה"
            },
        ],
       isPain: false
    },
    ear:{
        name:"כאבי אוזניים",
        qustions: [
      
        ],
        redFlags: [
            { 
                q:"האם התחיל כתוצאה מחשיפה לרעש?",
                y: "התחיל כתוצאה מחשיפה לרעש",
                n:"לא התחיל כתוצאה מחשיפה לרעש"
            },
            { 
                q:"האם יש סימני דלקת?",
                y: "עם סימני דלקת",
                n:"ללא סימני דלקת"
            },
            { 
                q:"האם הכאב חריג?",
                y: "עם כאב חריג",
                n:"ללא כאב חריג"
            },
            { 
                q:"האם יש הפרשות מהאוזן?",
                y: "עם הפרשות מהאוזן",
                n:"ללא הפרשות מהאזון"
            },


        ],
     isPain: true
    },
    eye:{
        name:"כאבי עיניים",
        qustions: [
            { 
                q:"האם התחיל כתוצאה מחבלה?",
                y: "כתוצאה מחבלה",
                n:"לא כתוצאה מחבלה"
            },
            { 
                q:"האם נכנס גוף זר לעין?",
                y: 'כתוצאה מגו"ז',
                n:'לא כתוצאה מגו"ז'
            },
            { 
                q:"האם יש סימני דלקת?",
                y: "עם סימני דלקת",
                n:"ללא סימני דלקת"
            },
            { 
                q:"האם הכאב חריג?",
                y: "עם כאב חריג",
                n:"ללא כאב חריג"
            },
            { 
                q:"האם יש הפרשות מהעין?",
                y: "עם הפרשות מהעין",
                n:"ללא הפרשות מהעין"
            },
            { 
                q:"האם יש טשטוש בראייה?",
                y: "עם טשטוש בראייה",
                n:"ללא טשטוש בראייה"
            },
            
        ],
     isPain: true
     },
    back:{
        name:"כאבי גב",
        qustions: [
            {
               q: "כתוצאה מחבלה?",
               y: "כתוצאה מחבלה",
               n:"לא כתוצאה מחבלה"
            },
            {
                q: "האם יש שינוי צורה?",
                y: "עם שינוי צורה",
                n: "ללא שינוי צורה"
             },
             {
                q: "האם יש תחושת חולשה כללית?",
                y: "מלווה בתחושת חולשה כללית",
                n:"כאבי גב אינם מלוום בתחושת חולשה כללית"
             },
             {
                q: "האם יש תחושת נימול בגפיים?",
                y: "עם תחושת נימול בגפיים",
                n:"ללא תחושת נימול בגפיים"
             },
             {
                q: "האם יש תחושת נימול ברגליים?",
                y: "עם תחושת נימול ברגליים",
                n: "ללא תחושת נימול ברגליים"
             },
             {
                q: "האם יש שליטה מלאה על סוגרים?",
                y:"עם שליטה מלאה על סוגרים",
                n: "ללא שליטה על סוגרים"
             },

        ],
        isPain: true
    },
    mousle:{
        name:"כאבי שרירים",
        qustions: [
            {
                q: "כתוצאה מחבלה?",
                y:"כתוצאה מחבלה",
                n:"לא כתוצאה מחבלה"
             },
             {
                q: "האם קיים דימום פנימי?",
                y: "עם דימום פנימי",
                n: "ללא דימום פנימי"
             },
             {
                q: "האם התחיל לאחר פעילות גופנית?",
                y:"התחיל לאחר פעילות גופנית",
                n: "לא התחיל לאחר פעילות גופנית"
             },
             {
                q: "האם יש הגבלה בתנועה?",
                y:"עם הגבלה בתנועה",
                n:"ללא הגבלה בתנועה"
             },
        ],
    },
    nows:{
        name:"דימום מהאף",
        qustions: [
           
        ],
        
    },
    ancle:{
        name:"כאבים בקרסול",
        qustions: [
            {
                q: "האם מסוגל לדרוך?",
                y: "מסוגל לדרוך על הרגל",
                n: "אינו מסוגל לדרוך על הרגל"
             },
             {
                q: "האם יש שינוי צורה בגפה?",
                y: "שינוי צורה בגפה",
                n:"ללא שינוי צורה בגפה"
             },
             {
                q: "האם יש אודם?",
                y: "עם אודם מקומי",
                n: "ללא אודם מקומי"
             },
             {
                q: "האם יש נפיחות?",
                y: "עם נפיחות",
                n: "ללא נפיחות"
             },
             
           
        ],
    
    },
    knee:{
        name:"כאבים בברכיים",
        qustions: [
            {
                q: "כתוצאה מחבלה?",
                y:"כתוצאה מחבלה",
                n:"לא כתוצאה מחבלה"
             },
        {
            q: "האם יש שינוי צורה בגפה?",
            y: "שינוי צורה בגפה",
            n:"ללא שינוי צורה בגפה"
         },
         {
            q: "האם יש אודם?",
            y: "עם אודם מקומי",
            n: "ללא אודם מקומי"
         },
         {
            q: "האם יש נפיחות?",
            y: "עם נפיחות",
            n: "ללא נפיחות"
         },
        ],
        
    },
    openWoond:{
        name:"פצע",
        qustions: [
            {
                q: "כתוצאה מחבלה?",
                y: "כתוצאה מחבלה",
                n: "לא כתוצאה מחבלה"
             },
             {
                q: "האם קיימים סימני דלקת?",
                y: "עם סימני דלקת",
                n: "ללא סימני דלקת"
             },
             {
                q: "האם הפצע מוגלתי?",
                y: "עם הפרשות מוגלתיות",
                n: "ללא הפרשות מוגלתיות"
             },
             {
                q:"האם יש אודם ונפיחות מפושטים על העור?",
                y: "עם אודם ונפיחות מפושטים על העור",
                n: "ללא אודם ונפיחות מפושטים על העור"
             },
             {
                q: "האם  פצע באיזור הצוואר / עמוד שדרה / איבן המין?",
                y: "הפצע באיזור הצוואר / עמוד שדרה / איבן המין",
                n:  "הפצע אינו באיזור הצוואר / עמוד שדרה / איבן המין",
             },
             { 
                q:"האם הפצע שחור / סגול",
                y: "הפצע שחור / סגול",
                n:"הפצע אינו שחור / סגול"
            },
            { 
                q:"האם הכאב חריג?",
                y: "עם כאב חריג",
                n: "ללא כאב חריג"
            },
        ],
     isPain: true
    },
    blister:{
        name:"שלפוחיות",
        qustions: [
           
        ],
        redFlags: [
            { 
                q:"האם קרה כתוצאה מכוויה?",
                y: "כתוצאה מכוויה",
                n:"לא כץוצאה מכוויה"
            },
            { 
                q: "האם השלפוחית מזוהמת?",
                y: "שלפוחית מזוהמת",
                n:"השלפוחית לא מזוהמת"
            },
            {
                q:"האם יש אודם ונפיחות מפושטים על העור?",
                y: "עם אודם ונפיחות מפושטים על העור",
                n: "ללא אודם ונפיחות מפושטים על העור"
             },
             {
                q: "האם  פצע באיזור הצוואר / עמוד שדרה / איבן המין?",
                y: "הפצע באיזור הצוואר / עמוד שדרה / איבן המין",
                n:  "הפצע אינו באיזור הצוואר / עמוד שדרה / איבן המין",
             },
             { 
                q:"האם הפצע שחור / סגול",
                y: "הפצע שחור / סגול",
                n:"הפצע אינו שחור / סגול"
            },
            { 
                q:"האם הכאב חריג?",
                y: "עם כאב חריג",
                n: "ללא כאב חריג"
            },
           
        ],
       isPain: false
    },
    dryLips:{
        name:"יובש בשפתיים",
        qustions: [
            { 
                q:"האם הסדקים עמוקים?",
                y: "עם סדקים עמוקים",
                n: "ללא סדקים עמוקים"
            },
           
        ],
       isPain: false
    },
    herpis:{
        name:"הרפס",
        qustions: [
            { 
                q:"האם הופיע במקום אחר למעט השפתיים" ,
                y: "הופיע במקום אחר למעט השפתיים",
                n: "לא הופיע במקום אחר למעט השפתיים"
            },
            { 
                q:"האם יש תלונות עיניות?",
                y: "עם תלונות עיניות",
                n: "ללא תלונות עיניות"
            },
            { 
                q:"האם היו 2 או יותר אירועים השנה?",
                y: "היו 2 או יותר אירועים השנה",
                n: "לא היו 2 אירועיים או יותר השבוע"
            },
            { 
                q:"האם הפצע חמור / מתפשט?",
                y: "הפצע חמור / מתפשט",
                n: "הפצע אינו חמור / מתפשט"
            },
            { 
                q:"האם יש סימני זיהום?",
                y: "עם סימני זיהום",
                n: "ללא סימני זיהום"
            },
            { 
                q:"האם היה טיפול שלא הביא הטבה תוך 5 ימים?",
                y: "טיפול לא הביא הטבה תוך 5 ימים",
                n: "לא נטל טיפול שלא היטיב תוך 5 ימים"
            },
           
        ],
       isPain: true
    },
    funges:{
        name:"פטריות",
        qustions: [
            { 
                q:"האם יש חום מקומי?",
                y: "עם חום מקומי",
                n: "ללא חום מקומי"
            },
            { 
                q:"האם האודם בולט על פני העור",
                y: "עם אודם בולט",
                n: "ללא אודם בולט"
            },
            { 
                q:"האם יש לאודם גבול ברור?",
                y: "אודם עם גבול ברור",
                n: "אודם ללא גבול ברור"
            },
            { 
                q:"האם אדמומי במרכז וחיוור בהיקף?",
                y: "אדמומי במרכז וחיוור בהיקף",
                n: "לא אדמומי במרכז וחיוור בהיקף"
            },
            { 
                q:"האם יש פצעים / בועות / שלפוחיות?",
                y: "עם פצעים / בועות / שלפוחיות",
                n: "ללא פצעים / בועות / שלפוחיות"
            },
            { 
                q:"האם האודם מפריש?",
                y: "אודם מפריש",
                n: "ללא אודם מפריש"
            },
           
        ],
       isPain: false
    },
    ingounTn:{
        name:"ציפורן חודרנית",
        qustions: [
            { 
                q:"האם קרה כתוצאה מחבלה?",
                y: "כתוצאה מחבלה",
                n: "לא כתוצאה מחבלה"
            },
            { 
                q:"האם חוזר ונשנה?",
                y: "חוזר ונשנה",
                n: "אינו חוזר ונשנה"
            },
            { 
                q:"האם יש הפרשות?",
                y: "עם הפרשות",
                n: "ללא הפרשות"
            },
           
        ],
       isPain: false
    },
    sh:{
        name:"מערכת השתן",
        qustions: [
            { 
                q:"האם יש צריבה במתן שתן?",
                y: "עם צריבה במתן שתן",
                n: "ללא צריכבה במתן שתן"
            },
            { 
                q:"האם יש דחיפות במתן שתן?",
                y: "דחיפות במתן שתן",
                n: "ללא דחיפות במתן שתן"
            },
            { 
                q:"האם יש תכיפות במתן שתן?",
                y: "תכיפות במתן שתן",
                n: "ללא תכיפות במתן שתן"
            },
           "צריבה?",
           "דחיפות?",
           "תכיפות?"
        ],
        isPain: false
    },
}];

export default redFlags;