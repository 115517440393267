
import './App.css';
import Header  from './componants/header.js';
import Calcs from './componants/calcs'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useState, useEffect } from 'react';
import MarpaTImes from './marpaTImes';
import MoreInfo from './componants/moreInfo';
import Login from './componants/login';
import Check from './componants/check'
import ResponsiveAppBar from './componants/appBarr';
function App({children}) {
  React.useLayoutEffect(() => {
    document.body.setAttribute("dir","rtl");
  });

  const [page, setPage] = useState('main');
 const [userName, setUserName] = useState('');
 const [company, setCompany] = useState('')

 
  
  return (
   
    <div className="App">
      <ResponsiveAppBar page={page} setPage={setPage} userName />
       <LocalizationProvider dateAdapter={AdapterDayjs}>
       {page === 'main' ? 
       <div>
      {children}
      <Header />
     <Calcs />
     <MarpaTImes />
     <MoreInfo />
     </div>
     : page === 'login' ? <Login page={page} setPage={setPage} userName={userName} setUserName={setUserName} company={company} setCompany={setCompany} /> : page === 'check' ? <Check userName={userName} company={company}/> : '' }
    </LocalizationProvider>
    
    </div>
    
  );
}

export default App;
