import React, {useEffect, useState} from 'react';
import {Card, Typography, Grid, Autocomplete, TextField} from  '@mui/material';
import redFlags from '../utils/redFlagsarry';
const RedFlags = () => {

    let enames = [];
    const [value, setValue] = useState();
    const [treetmant, settreetmant] = useState([]);
    const [qustion, setqustion] = useState([]);
    const [redf, setredf] = useState([]);

    const handleChange = (val) =>{
        redFlags.map((e) => {
            for (const key in e) {
                if (e.hasOwnProperty.call(e, key)) {
                    const element = e[key];
                    if (element.name === val){
                        console.log(element)
                        setqustion(element.qustions)
                        setredf(element.redFlags);
                        settreetmant(element.treetmant)
                    }
                 }
                }
             } )
    }


    useEffect(()=>{      
        redFlags.map((e) => {
            for (const key in e) {
                if (e.hasOwnProperty.call(e, key)) {
                        const element = e[key];
                        enames.push(element.name)
                 }
            }
})

    })


    return (
      <Card 
      sx={{
        margin: '2em 1em',
        padding: '1em',
      }}
      id="ana">
            <Typography variant="h5"> אנמנזה </Typography>
            <Autocomplete
                id="free-solo-demo"
                fullWidth
                freeSolo
                value={value}
                options={enames}
                 onChange={(event, newValue) => { 
                   console.log(newValue);
                   setValue(newValue)
                   handleChange(newValue);

                 }}
                
                
                renderInput={(params) => <TextField {...params} label='חיפוש' />}
                />

                <Typography variant="h6">דגלים אדומים: </Typography>
                {redf.map((i, e) => {
                         return( <Typography> {e + 1}. {i}</Typography>)
                })};

                <Typography variant="h6">שאלות ספציפיות: </Typography>
                {qustion.map((i, e) => {
                         return( <Typography> {e + 1}. {i}</Typography>)
                })};

                <Typography variant="h6">טיפול: </Typography>
                {treetmant.map((i, e) => {
                         return( <Typography> {i}</Typography>)
                })}
              
               
      </Card>
    );
};

export default RedFlags;