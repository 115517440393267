import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { heIL } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/he';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";

const cacheRtl = createCache({
  key: "muirtl",
  // prefixer is the only stylis plugin by default, so when
  // overriding the plugins you need to include it explicitly
  // if you want to retain the auto-prefixing behavior.
  stylisPlugins: [prefixer, rtlPlugin]
});


const theme = createTheme({
  direction: 'rtl',
  palette:{
    primary:{
      main: '#1ABC9C',
      contrastText: '#fff'
    },
    secondary:{
      main: '#3498DB'
    }
  },
  typography:{
    fontFamily: 'Varela Round'
  },
 
},
heIL,
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CacheProvider value={cacheRtl}>
    <LocalizationProvider localeText={heIL.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs} adapterLocale={'he'}>
  <ThemeProvider theme={theme}>
  <React.StrictMode>
    
    <App />
  </React.StrictMode>
  </ThemeProvider>
  </LocalizationProvider>
  </CacheProvider>

);
document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");


