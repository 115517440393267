import {React, useState, useEffect} from 'react'
import { Button,Typography, Box, DialogTitle  ,IconButton ,Toolbar,AppBar ,Divider ,List ,ListItemButton,FormControlLabel,Radio, FormLabel,RadioGroup ,ListItemText ,Dialog, Card,TextField, Autocomplete, Stack, MenuItem,InputLabel,Select, FormControl  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import redFlags from '../utils/qusqx';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { EditLocationAlt } from '@mui/icons-material';
const Qx = ({handleClose}) => {
    const [value, setValue] = useState();
    const [currentMainComp, setCurrentMainCop] = useState();
    const [age, setAge] = useState('');
    const [gender, setGender] = useState("בן ")
    const [profile, setProfile] = useState(" פרופיל 97");
    const [profileNum, setProfileNum] = useState(" פרופיל 97");
    const [bgrd, setBgrd] = useState(' בריא בד"כ ' )
    const [medications, setMedications] = useState("אינו נוטל תרופות קבועות ");
    const [allergies, setAllergies] = useState("ללא אלרגיה ידועה ");
    const [surgery, setSurgery] = useState("ללא ניתוחים בעבר ");
    const [smoking, setSmoking] = useState("אינו מעשן ");
    const [duration, setDuration] = useState("מזה כיומיים ");
    const [painType, setPainType] = useState("");
    const [radi, setRadi] = useState("כאב אינו מקרין ");
    const [relieves, setRelieves] = useState("כאב מוקל במנוחה ");
    const [worse, setWorse] = useState("כאב מחמיר במאמץ ");
    const [finalAna, setFinalAna] = useState();
    let enames = [];
   let arr = []
    let a = new Array(50).fill("0")
    let anamnezaStr ="";

   


    useEffect(()=>{      
        redFlags.map((e) => {
            for (const key in e) {
                if (e.hasOwnProperty.call(e, key)) {
                        const element = e[key];
                        enames.push(element.name)
                 }
            }
})

    })

    const handleChange = (val) =>{
        redFlags.map((e) => {
            for (const key in e) {
                if (e.hasOwnProperty.call(e, key)) {
                    const element = e[key];
                    if (element.name === val){
                        setCurrentMainCop(element)
                        // console.log(element.qustions)
                        // setqustion(element.qustions)
                        // setredf(element.redFlags);
                        // settreetmant(element.treetmant)
                    }
                 }
                }
             } )
            
    }
    const handleAgeChange = (event) => {
        setAge(event.target.value);
      };

      const createAna = () =>{
        if(!currentMainComp){
          alert("יש לבחור תלונה עיקרית")
          return;
        }
        const app = gender.includes("בן") ? "פנה בשל " : 'פנתה בשל '
        const pro = profile != 'ס"ל ' ? profile : '';
        console.log(profile);
        console.log(profile === 'ס"ל ');
        anamnezaStr = gender + age + ", " + profileNum + " " + pro + ", "+ bgrd + ", " + medications + ", "+ allergies + ", " + surgery + smoking + ".\n" + app  + currentMainComp.name + " " + duration + "\n" + painType + "\n" + relieves + "\n" + worse + "\n"+ arr;
        if(gender.includes("בת")){
          const tempstr = anamnezaStr.replace("בריא","בריאה").replace("אינו מעשן", "אינה מעשנת").replace("מעשן", "מעשנת").replace("עבר", "עברה").replace("נוטל", "נוטלת").replace("אינו", "אינה");
          
          setFinalAna(tempstr);
         }else{
           setFinalAna(anamnezaStr);
           

         }

         
        anamnezaStr = "";
        arr = [];
      }
      const [open, setOpen] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleCloseedit = () => {
        setOpen(false);
      };

    

      
  return (
    <Stack spacing={2}>
    <AppBar sx={{ position: 'relative' }}>
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
        אנמנזה
      </Typography>
      <Button autoFocus color="inherit" onClick={handleClose}>
        סגור
      </Button>
    </Toolbar>
  </AppBar>
  <Stack 
  spacing={3}
  // divider={<Divider orientation="horizontal" flexItem />}
      sx={{
        margin: '2em 1em',
        padding: '1em',
      }}
      id="ana">
           
      <Stack>
      <FormControl>
        
            <FormLabel id="gender-label">מין</FormLabel>
            <RadioGroup
                aria-labelledby="gender-label"
                defaultValue="yes"
                name="radio-buttons-group"
               
                 onChange={(_event, value)=> value === "yes" ? setGender("בן ") : setGender("בת ")}
            >
                <FormControlLabel value="yes" control={<Radio />} label="זכר" />
                <FormControlLabel value="no" control={<Radio />} label="נקבה" />
               
             </RadioGroup>
            </FormControl>
      </Stack>

      <Stack>
      
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-autowidth-label">{gender.includes("בן") ? "גיל המטופל" : "גיל המטופלת"}</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={age}
          onChange={handleAgeChange}
          autoWidth
          label="Age"
        >
          <MenuItem value="">
            <em>לא ידוע</em>
          </MenuItem>
          {a.map((v,i)=>{
            // console.log(v);
            return ( <MenuItem value={i + 17}>{i + 17 + " "}</MenuItem>)
          })}
                
         
        </Select>
      </FormControl>
      </Stack>
      <Stack>
      <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">פרופיל</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="97"
                name="radio-buttons-group"
                onChange={(event, value)=>  {
                    const more = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[0].value;
                   setProfileNum("פרופיל " + value )
                }
                }
            >
                <FormControlLabel value="97" control={<Radio />} label="97" />
                <FormControlLabel value="82" control={<Radio />} label="82" />
                <FormControlLabel value="64" control={<Radio />} label="64" />
                <FormControlLabel value="45" control={<Radio />} label="45" />
                <FormControlLabel value="21" control={<Radio />} label="21" />
             </RadioGroup>
            </FormControl>
            <TextField onChange={(event, value)=>  setProfile('ס"ל ' +  event.target.value)} id="standard-basic" label="סעיפי ליקוי" variant="standard" />

           
      </Stack>
      <Stack>

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{gender.includes("בן") ? 'בריא בד"כ?' : 'בריאה בד"כ?'}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                onChange={(event, value)=>  {
                    const more = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[0].value;
                    value === "yes" ? setBgrd('בריא בד"כ, ') : setBgrd("ברקע " + more + ", ")
                }
                }
            >
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel value="no" control={<Radio />} label="לא" />
               
             </RadioGroup>
            </FormControl>
            <TextField onChange={(event, value)=>  setBgrd("ברקע " + event.target.value)} id="standard-basic" label="פירוט" variant="standard" />

      </Stack>
      <Stack>
      <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{gender.includes("בן") ? "נוטל תרופות קבועות?" : "נוטלת תרופות קבועות?"}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="no"
                name="radio-buttons-group"
                onChange={(event, value)=>  {
                    const more = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[0].value;
                    value === "no" ? setMedications("אינו נוטל תרופות קבועות ") : setMedications("נוטל " + more + ", ")
                    value === "no" ? setMedications("אינו נוטל תרופות קבועות ") : setMedications("נוטל " + more + ", ")
                }
                }
            >
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel value="no" control={<Radio />} label="לא" />
               
             </RadioGroup>
            </FormControl>
            <TextField onChange={(event, value)=>  setMedications("נוטל " + event.target.value)} id="standard-basic" label="פירוט" variant="standard" />
      </Stack>
      <Stack>
      <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"> האם יש אלרגיה או רגישות ידועה?</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="no"
                name="radio-buttons-group"
                onChange={(event, value)=>  {
                    const more = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[0].value;
                    value === "no" ? setAllergies("ללא אלרגיה או רגישות ידועה, ") : setAllergies( more + ", ")
                }
                }
            >
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel value="no" control={<Radio />} label="לא" />
               
             </RadioGroup>
            </FormControl>
            <TextField onChange={(event, value)=>  setAllergies("עם אלרגיה רגישות -  " + event.target.value)} id="standard-basic" label="פירוט" variant="standard" />
      </Stack>

      <Stack>
      <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">ניתוחים בעבר?</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="no"
                name="radio-buttons-group"
                onChange={(event, value)=>  {
                    const more = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[0].value;
                    value === "no" ? setSurgery("ללא ניתוחים בעבר, ") : setSurgery("עבר ניתוח " + more + ", ")
                }
                }
            >
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel value="no" control={<Radio />} label="לא" />
               
             </RadioGroup>
            </FormControl>
            <TextField onChange={(event, value)=>  setSurgery("עבר ניתוח  " + event.target.value)} id="standard-basic" label="פירוט" variant="standard" />
      </Stack>

      <Stack>
      <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"> {gender.includes("בן") ? "מעשן?" : "מעשנת?"}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="no"
                name="radio-buttons-group"
                onChange={(event, value)=>  {
                    const more = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[0].value;
                // console.log(more)
                    value === "no" ? setSmoking("לא מעשן") : setSmoking(" מעשן " + more)
                }
                }
            >
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel value="no" control={<Radio />} label="לא" />
               
             </RadioGroup>
            </FormControl>
            <TextField  onChange={(event, value)=>  setSmoking("מעשן " + event.target.value)} id="standard-basic" label="פירוט" variant="standard" />
      </Stack>
      <Typography variant="h5"> בחירת תלונה עיקרית: </Typography>
            <Autocomplete
                id="free-solo-demo"
                fullWidth
                freeSolo
                value={value}
                options={enames}
                 onChange={(event, newValue) => { 
                //    console.log(newValue);
                   setValue(newValue)
                   handleChange(newValue);

                 }}
                
                
                renderInput={(params) => <TextField {...params} label='חיפוש' />}
                />

            <TextField  onChange={(event, value)=>  setDuration("מזה כ " + event.target.value)} id="standard-basic" label="משך התלונה " variant="standard" />

      {/* שאלות כלליות - רק אם התלונה מערבת כאב */}
      {currentMainComp && currentMainComp.isPain ? <Typography variant='h5'> שאלות כלליות ל{currentMainComp.name}</Typography> : '' }

      {currentMainComp && currentMainComp.isPain ?   <TextField  onChange={(event, value)=>  setPainType(" כאב " + event.target.value)} id="standard-basic" label="סוג הכאב - לדוג' - שורף" variant="standard" /> : '' }
      {currentMainComp && currentMainComp.isPain ?  <Stack> <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"> האם הכאב מקרין?</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="no"
                name="radio-buttons-group"
                onChange={(event, value)=>  {
                    const more = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1].childNodes[0].value;
                // console.log(more)
                    value === "no" ? setRadi("כאב אינו מקרין ") : setRadi("כאב מקרין ל " + more)
                }
                }
            >
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel value="no" control={<Radio />} label="לא" />
               
             </RadioGroup>
            </FormControl>
                <TextField  onChange={(event, value)=>  setRadi("כאב מקרין ל " + event.target.value)} id="standard-basic" label="לאן מקרין?" variant="standard" />
                </Stack>     : '' }
                {currentMainComp && currentMainComp.isPain ?   <TextField  onChange={(event, value)=>  setRelieves("כאב מוקל ב" + event.target.value)} id="standard-basic" label="גורמים מקלים" variant="standard" /> : '' }
                {currentMainComp && currentMainComp.isPain ?   <TextField  onChange={(event, value)=>  setWorse("כאב מחמיר ב" + event.target.value)} id="standard-basic" label="גורמים מחמירים" variant="standard" /> : '' }

               
              
      {/* שאלות ספציפיות - רק אם נבחרה תלונה עיקרית */}
      {currentMainComp ? <Typography variant='h5'> שאלות ספציפיות ל{currentMainComp.name}</Typography> : ''}
      
      {currentMainComp ? currentMainComp.qustions.map((e,i)=>{
        arr[i] = e.n
       return(
        
        <FormControl>
        <FormLabel id="demo-radio-buttons-group-label"> {e.q}</FormLabel>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="no"
            name="radio-buttons-group"
            onChange={(event, value)=>  value === 'yes' ? arr[i] = e.y : arr[i] = e.n }
            
        >
            <FormControlLabel value="yes" control={<Radio />} label="כן" />
            <FormControlLabel value="no" control={<Radio />} label="לא" />
           
         </RadioGroup>
        </FormControl>
       )
      }) : ''}

      
      </Stack>
      <Dialog onClose={handleCloseedit} open={open} fullWidth>
      <DialogTitle>אנמנזה ל{currentMainComp ? currentMainComp.name : '' }</DialogTitle>
      <TextField
          id="outlined-multiline-static"
          label="אנמנזה"
          multiline
          rows={20}
          defaultValue={finalAna}
          value={finalAna}
          fullWidth
          onChange={(e) =>{
            setFinalAna(e.target.value)
          }}
          
        />
        <Stack direction="horizontal" sx={{display: "flex", justifyContent: "flex-end"}}>
         <Button
          endIcon={<ContentCopyIcon />}
         variant="text"
          onClick={(() => {
          navigator.clipboard.writeText(finalAna)
          handleClose()
         })}>העתקת האנמנזה
         </Button>

          <Button variant="text"
           onClick={handleCloseedit} >
            סגירה
          </Button>
          </Stack>
    </Dialog>
    
      <Button 
     
      sx={{margin: "10px !important"}}
      
      variant="contained" onClick={()=> {
        createAna();
         handleClickOpen();
      }}>יצירת אנמנזה</Button>
  </Stack>
  )
}

export default Qx