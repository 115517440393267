import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { Card, Grid, Typography, FormControl, Radio, FormLabel, FormControlLabel, RadioGroup } from '@mui/material';
import marpatimesobj from './utils/marpaTimesobj'


const MarpaTImes = () => {

    const marpa = [];
    const [value, setValue] = useState('');
   const [more, setMore] = useState('')
    const [when, setwhen] = useState('')
    const [phoneNumber, setphoneNumber] = useState('')
    const [service, setService] = useState('lab');
    const [name, setName] = useState('');


    const handleChange = (val) =>{     
        marpatimesobj.map((e) => {
        for (const key in e) {
            if (e.hasOwnProperty.call(e, key)) {
                const element = e[key];
                if(element.name === val ){
                    setName(element.name + " - " + element.fullName)
                    if(service === 'lab'){
                        setphoneNumber(element.lab.phoneNumber)
                        setwhen(element.lab.time)
                        setMore(element.lab.more)
                       
                        
                    }else if(service === 'pharmacy'){
                        setphoneNumber(element.pharmacy.phoneNumber)
                        setwhen(element.pharmacy.time)
                        setMore(element.pharmacy.more)

                    }
                    else if(service === 'xrey'){
                        setphoneNumber(element.xrey.phoneNumber)
                        setwhen(element.xrey.time)
                        setMore(element.xrey.more)
                    }
                    //console.log(element)
                }
               
               
            }
        }
    })
    }

    useEffect(() => {
        setService(service);
        console.log(service)
        handleChange(value);
    }, [service]);


    const handleServiceChange = (e) =>{
        setService(e.target.value);
        console.log(service)
        handleChange(value);
    }

    useEffect(()=>{      
        marpatimesobj.map((e) => {
            for (const key in e) {
                if (e.hasOwnProperty.call(e, key)) {
                        const element = e[key];
                        marpa.push(element.name)
                 }
            }
})
// console.log(marpa)
    })
    return (
       <Card 
       sx={{
        margin: '2em 1em',
    padding: '1em',

       }}
       id="times"> 
         <Typography variant="h5">שעות פעילות מרפאות</Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>

                <Autocomplete
                id="free-solo-demo"
                fullWidth
                freeSolo
                value={value}
                options={marpa}
                onChange={(event, newValue) => { 
                    setValue(newValue);
                    handleChange(newValue);
                }}
                
                
                renderInput={(params) => <TextField {...params} label='חיפוש מרפ"א' />}
                />

                

                </Grid>
                <Grid item  xs={12} md={4} >
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">סוג שירות</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={service}
                        onChange={handleServiceChange}
                    >
                        
                        <FormControlLabel   value="lab" control={<Radio />} label="מעבדה" />
                        <FormControlLabel value="pharmacy" control={<Radio />} label="בית מרקחת" />
                        <FormControlLabel value="xrey" control={<Radio />} label="רנטגן" />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item  xs={12} md={4} >

                <Typography variant="h6"> {name}</Typography>
                <Typography>שעות פתיחה: {when}</Typography>
                <Typography>מס' טלפון: {phoneNumber}</Typography>
                <Typography>{more}</Typography>
                


                </Grid>
            </Grid>
       </Card>
    );
};

export default MarpaTImes;