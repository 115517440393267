import React from 'react'
import {useState} from 'react'
import { Stack, Typography, AntSwitch, Button, Grid,Card, Switch  } from '@mui/material';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import weekList from '../utils/weeklist';

const WeekCheck = (props) => {

    const checkResult = [{name: 'CAT', isOk: false},{name: 'Combat Gauze', isOk: false},{name: 'First Care', isOk: false},{name: 'Airway', isOk: false},{name: 'Blanket', isOk: false},{name: 'Gloves', isOk: false},{name: 'Pulse Oximeter', isOk: false},{name: 'gauze pad', isOk: false},{name: '14G', isOk: false},{name: 'VB', isOk: false},{name: 'IV line', isOk: false},{name: 'Hartman', isOk: false},{name: '18G', isOk: false},{name: 'loiko', isOk: false},{name: 'sponge', isOk: false},{name: 'Syringe', isOk: false},{name: 'Needle', isOk: false},{name: 'eged', isOk: false},{name: 'Elastic bandage', isOk: false},{name: 'Metalin bandage', isOk: false},{name: 'tri', isOk: false},{name: 'Paracetamole', isOk: false},{name: 'malac', isOk: false},{name: 'Flashlight', isOk: false},{name: 'Batteries', isOk: false},{name: 'Thermometer', isOk: false},{name: 'T.B', isOk: false},{name: 'p.m', isOk: false},{name: 'marker', isOk: false},{name: 'list', isOk: false}, ];
    const [loading, setLoading] = useState('שליחה');

    
   
    const changeCheck = (e) =>{
    //  console.log(e)
        
            const date = e.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[3].childNodes[0].childNodes[1].childNodes[0].value;
            
            console.log(date)
            let found = false, index = 0;
            for(let i = 0; i < checkResult.length; i++){
                
                if(e.target.value == checkResult[i].name){
                    found = true;
                    index = i;
                    break;
                }
            

        }
        if(found){
            checkResult[index] = {name: e.target.value, isOk: e.target.checked, date}
        }else{
             checkResult.push({name: e.target.value, isOk: e.target.checked, date})
           
        }
    if(checkResult.length == 0) checkResult.push({name: e.target.value, isOk: e.target.checked, date})
        
  
        
  
       
    // console.log(checkResult)
    }
    const dateChangeCheck =(e, v) =>{
       
            const month = parseInt(e.$M) + 1;
            const dateElse = e.$D + '/' + month + '/' + e.$y;
           
            const isChecked = document.getElementById(v).checked;

             console.log(dateElse)
            // console.log(v);
            let found = false, index = 0;
            for(let i = 0; i < checkResult.length; i++){
                
                if(v === checkResult[i].name){
                    found = true;
                    index = i;
                    break;
                }
            

             }
             checkResult[index] = {name: v, isOk: isChecked, date: dateElse}
             console.log(checkResult)
        //      if(found){
        //         checkResult[index] = {name: v, isOk: isChecked, date: dateElse}
        //         console.log(checkResult)
        //     }else{
        //          checkResult.push({name: v, isOk: isChecked,date: dateElse})
               
        //     }
        // if(checkResult.length == 0) checkResult.push({name: e.target.value, isOk: isChecked, date: dateElse})
            
      

           
        // console.log(checkResult)
    }

    const sendMail = async () =>{
        let resultString = ''
        for (let i = 0; i < checkResult.length; i++){
            let txt1;
            if(checkResult[i].isOk){
                txt1 = 'Is Ok'
            }else{
                txt1 = 'Replace!'
            }
            resultString += 'name: ' + checkResult[i].name +   '\n' + txt1 +  '\n' + 'Date: ' +  '\n'+  checkResult[i].date +  '\n' +  '\n'
        }
        // console.log(resultString)
       
        setLoading('שולח...')
    //   options.data = checkResult;
    //   options.title = 'בדיקת ווסט של'
    let options = {
        method: 'POST',
        url: 'https://mail-sender-api1.p.rapidapi.com/',
        headers: {
          'content-type': 'application/json',
          'X-RapidAPI-Key': '1b5f0b111cmsh96e0633dc3b86ddp1b32f3jsndf2ee46d7073',
          'X-RapidAPI-Host': 'mail-sender-api1.p.rapidapi.com'
        },
        data: {
          sendto: 'maakavim97@gmail.com',
          name: 'בדיקת ווסט שבועית',
          replyTo: 'Your Email address where users can send their reply',
          ishtml: 'false',
          title: `בדיקת הווסט השבועית של ${props.userName} - ${props.company}`,
          body: resultString + ' is the result'
        }
      };
      
          try {
              const response = await axios.request(options);
              if (response.data.error == false) setLoading('נשלח בהצלחה!')
          } catch (error) {
              console.error(error);
              setLoading('לא נשלח! נסה שוב')
          }
    }

  return (
    <Stack spacing={2} sx={{padding: "2em 0.8em"}}>
        {weekList.map((e,i) =>{
            
            return (<Card sx={{padding: "8px"}}>
            <Grid xs={12} container>
                <Grid item xs={6}>
                    <Typography variant="h5">{e.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                <Typography variant="h6">כמות: {e.quantity}</Typography>
                </Grid>
                <Grid item xs={6} alignItems="center">
                <Stack direction="row"  spacing={1} alignItems="center">
                            <Typography>לא תקין</Typography>
                            <Switch  id={e.Ename} value={e.Ename} onChange={changeCheck}/>
                            <Typography> תקין</Typography>
                        </Stack>
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        label="תוקף: "
                        // defaultValue={now}
                        // onChange={dateChange}
                        // value={e.Ename}
                        variant="standard"
                        format={'DD/MM/YY'}
                        views={[ 'day', 'month', 'year']}
                        onChange={(a)=> dateChangeCheck(a, e.Ename)}
                        
                     />
                </Grid>
                
            </Grid>
        </Card> )
        })}
        

        <Button onClick={sendMail} variant='outlined' value={loading}>{loading}</Button>

    </Stack>
  )
}

export default WeekCheck