import React from 'react'
import {useState} from 'react'
import {ToggleButtonGroup, ToggleButton, Card } from '@mui/material'
import DayCheck from './dayCheck'
import WeekCheck from './weekCheck'
const Check = (props) => {

    const [checkType, setcheckType] = React.useState('day');

    const handleChange = (event, newcheckType) => {
        setcheckType(newcheckType);
    };


  return (
    <Card>
     <ToggleButtonGroup
      color="primary"
      value={checkType}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      fullWidth
    >
      <ToggleButton value="day">בדיקה יומית</ToggleButton>
      <ToggleButton value="week">בדיקה שבועית</ToggleButton>
      {/* <ToggleButton value="ios">iOS</ToggleButton> */}
    </ToggleButtonGroup>
    {checkType === 'day' ? <DayCheck userName={props.userName} company={props.company}/> : <WeekCheck userName={props.userName} company={props.company}/>}
    </Card>
  )
}

export default Check