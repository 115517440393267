
const allLIsts =[
    [
        "מכנס תחתון + גופייה תחתונה",
        "מדי עבודה (מדי ב')",
        "מעיל פליז",
        "חרמונית (סרבל מרופד) או מעיל ומכנס מרופדים (מעיל ומכנס דובון)",
        'שקיות חימום (לפי הנחיות קרפ"ר',
        "כובע",
        "כפפות",
        "גרביים",
        'שק"ש',
        "מזרן מבודד מהרצפה (מזרן מערבים) במקרה של מגע עם הקרקע"
    ],
    [
        "מכנס תחתון + גופייה תחתונה",
        "מדי עבודה (מדי ב')",
        "מעיל פליז",
        'מעיל ומכנס סערה (חלפ"ס) אם יש גשם',
        "כובע",
        "כפפות",
        "גרביים",
        "זוג מדים יבשים + מכנס וגופייה תחתונים יבשים + גרביים יבשות",
        "נעליים צבאיות",
        'שק"ש',
        "מזרן מבודד מהרצפה (מזרן מערבים) במקרה של מגע עם הקרקע"
    ],
    [
        "מכנס תחתון + גופייה תחתונה",
        "מדי עבודה (מדי ב')",
        "מעיל פליז",
        'מעיל ומכנס סערה (חלפ"ס) אם יש גשם',
        "נעליים קנדיות (באימון של עד שעתיים רצוף לא חובה)",
        "חרמונית (סרבל מרופד) או מעיל ומכנס מרופדים (מעיל ומכנס דובון)",
        'שקיות חימום (לפי הנחיות קרפ"ר',
        "כובע",
        "כפפות",
        "גרביים",
        'שק"ש',
        "זוג מדים יבשים + מכנס וגופייה תחתונים יבשים + גרביים יבשות",
        "מזרן מבודד מהרצפה (מזרן מערבים) במקרה של מגע עם הקרקע"
    ],
    [
        "מכנס תחתון + גופייה תחתונה (עדיף טרמקס)" ,
        "מדי עבודה (מדי ב')",
        "גרביים",
        'שק"ש',
        "מזרן מבודד מהרצפה (מזרן מערבים) במקרה של מגע עם הקרקע"
    ]
];

export default allLIsts;