import React from 'react'
import {useState} from 'react'
import { Stack, Typography, AntSwitch, Button, Grid,Card, Switch  } from '@mui/material';
import axios from 'axios';

const DayCheck = (props) => {
   
    
    const checkResult = [{name: 'CAT', isOk: false}, {name: 'FC',  isOk: false}, {name: 'CG',  isOk: false},{name: 'pulse',  isOk: false},{name: 'AW',  isOk: false}, {name: 'malac',  isOk: false},{name: 'IV',  isOk: false}];
    const [loading, setLoading] = useState('שליחה');
   
    const changeCheck = (e) =>{
        console.log(props)
     
       
            let found = false, index = 0;
            for(let i = 0; i < checkResult.length; i++){
                
                if(e.target.value == checkResult[i].name){
                    found = true;
                    index = i;
                    break;
                }

        }
        
        if(found){
            checkResult[index] = {name: e.target.value, isOk: e.target.checked}
        }else{
             checkResult.push({name: e.target.value, isOk: e.target.checked})
           
        }
    if(checkResult.length == 0) checkResult.push({name: e.target.value, isOk: e.target.checked})
       
       
    }
    const sendMail = async () =>{
        
     
        let resultString = ''
        for (let i = 0; i < checkResult.length; i++){
            resultString += 'name: ' + checkResult[i].name +  '\n' + ' isOk? ' + checkResult[i].isOk +'\n'
        }
        
       
        setLoading('שולח...')
    //   options.data = checkResult;
    //   options.title = 'בדיקת ווסט של'
    let options = {
        method: 'POST',
        url: 'https://mail-sender-api1.p.rapidapi.com/',
        headers: {
          'content-type': 'application/json',
          'X-RapidAPI-Key': '1b5f0b111cmsh96e0633dc3b86ddp1b32f3jsndf2ee46d7073',
          'X-RapidAPI-Host': 'mail-sender-api1.p.rapidapi.com'
        },
        data: {
          sendto: 'maakavim97@gmail.com',
          name: 'בדיקת ווסט יומית',
          replyTo: 'Your Email address where users can send their reply',
          ishtml: 'false',
          title: `בדיקת הווסט היומית של ${props.userName} - ${props.company}`,
          body: resultString + ' is the result'
        }
      };
      
      
          try {
          
              const response = await axios.request(options);
              console.log(response)
              if (response.data.error == false) setLoading('נשלח בהצלחה!')
          } catch (error) {
              console.error(error);
              setLoading('לא נשלח! נסה שוב')
          }
    }
  return (
    <Stack elevation={1} >
   <Grid container spacing={0} justifyContent='center'>
                    <Typography color='primary' variant='h4'>בדיקת ווסט יומית</Typography>
//         <Grid xs={12}>
            <Card justifyContent='center' elevation={0} sx={{
                 display: "flex",
                 justifyContent: 'center'
            } }>
            
               
                <Grid container xs={10} justifyContent='center' alignItems='center'>
                    <Grid xs={4} >
                        <Typography  variant='h5'>שם</Typography>

                    </Grid>
                    <Grid xs={4}>
                        <Typography variant='h5'>כמות</Typography>

                    </Grid>
                    <Grid xs={4}>
                    <Typography variant='h5'>תקינות</Typography>
                    </Grid>

                    <Grid xs={4}>
                        <Typography  variant='h6'>C.A.T</Typography>

                    </Grid>
                    <Grid xs={2}>
                        <Typography variant='h6'>4</Typography>

                    </Grid>
                    <Grid xs={6}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>לא תקין</Typography>
                            <Switch value='CAT' onChange={changeCheck}/>
                            <Typography> תקין</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={4}>
                        <Typography  variant='h6'>F.C</Typography>

                    </Grid>
                    <Grid xs={2}>
                        <Typography variant='h6'>3</Typography>

                    </Grid>
                    <Grid xs={6}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>לא תקין</Typography>
                            <Switch value='FC' onChange={changeCheck}/>
                            <Typography> תקין</Typography>
                        </Stack>
                    </Grid>

                    <Grid xs={4}>
                        <Typography  variant='h6'>C.G</Typography>

                    </Grid>
                    <Grid xs={2}>
                        <Typography variant='h6'>1</Typography>

                    </Grid>
                    <Grid xs={6}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>לא תקין</Typography>
                            <Switch value='CG' onChange={changeCheck}/>
                            <Typography> תקין</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={4}>
                        <Typography  variant='h6'>פאלס</Typography>

                    </Grid>
                    <Grid xs={2}>
                        <Typography variant='h6'>1</Typography>

                    </Grid>
                    <Grid xs={6}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>לא תקין</Typography>
                            <Switch value='pulse' onChange={changeCheck}/>
                            <Typography> תקין</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={4}>
                        <Typography  variant='h6'>A.W</Typography>

                    </Grid>
                    <Grid xs={2}>
                        <Typography variant='h6'>2</Typography>

                    </Grid>
                    <Grid xs={6}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>לא תקין</Typography>
                            <Switch value='AW' onChange={changeCheck}/>
                            <Typography> תקין</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={4}>
                        <Typography  variant='h6'>מלע"כ</Typography>

                    </Grid>
                    <Grid xs={2}>
                        <Typography variant='h6'>1</Typography>

                    </Grid>
                    <Grid xs={6}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>לא תקין</Typography>
                            <Switch value='malac' onChange={changeCheck}/>
                            <Typography> תקין</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={4}>
                        <Typography  variant='h6'>ערכה לוריד</Typography>

                    </Grid>
                    <Grid xs={2}>
                        <Typography variant='h6'>1</Typography>

                    </Grid>
                    <Grid xs={6}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>לא תקין</Typography>
                            <Switch value='IV' onChange={changeCheck}/>
                            <Typography> תקין</Typography>
                        </Stack>
                    </Grid>

                </Grid>
                

     
            </Card>
//         </Grid>
            
   </Grid>
   
   <Button onClick={sendMail} variant='outlined' value={loading}>{loading}</Button>
   </Stack>
  )
}

export default DayCheck